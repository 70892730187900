<template>
    <v-card
        class="elevation-4 mx-auto"
        width="650"
    >
        <v-card-title
            class="py-2 pl-8 caption primary--text font-weight-bold text-uppercase secondary"
        >
            <v-spacer></v-spacer>
                <span>End of Term Reports</span>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-row class="pa-4">                    
            <v-col cols="9">
                <v-list>                            
                    <v-list-item
                        v-for="(item, i) in menuItems"
                        :key="i"
                        link
                        @click="setMenu(item)"
                    >
                        <v-list-item-icon>
                            <v-icon 
                                v-text="item.icon"
                                color="primary"
                            ></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title 
                                v-text="item.text"
                                class="font-weight-normal text-uppercase primary--text"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list> 
            </v-col>
            <v-col cols="3">
                <v-img
                    src="../assets/logo.png"
                    max-width="120"
                    contain
                    class="d-flex"
                ></v-img> 
            </v-col>
        </v-row>          
    </v-card>    
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    name: 'MainMenu',
    data: () => ({
        menuItems: [           
            {
                icon: 'mdi-keyboard',
                text: 'Enter Marks (Subject Teachers)',
                menu: 'enter-marks'
            },
            {
                icon: 'mdi-pencil',
                text: 'Edit/View Term Reports (Form Teachers)',
                menu: 'edit-view-term-reports'
            }
        ],
             
    }),

    computed: {
        ...mapGetters({
            user: 'auth/getEmployeeSignedIn',
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            getMarkSheetLoading: 'termReports/getMarkSheetLoading',
            getEditViewTermReportsOverlay: 'termReports/getEditViewTermReportsOverlay',            
            id: 'auth/getEmployeeId',
        }),
    },

   
    methods: {
        ...mapMutations({
            mainMenu: 'termReports/setMainMenu',
            enterMarks: 'termReports/setEnterMarks',
            editViewTermReports: 'termReports/setEditViewTermReports',
            welcomeMessage: 'termReports/setWelcomeMessage',
        }),
        
        
        setMenu(item){
            this.welcomeMessage(false);
            switch(item.menu){               
                case 'enter-marks':
                    this.mainMenu(false);
                    this.enterMarks(true);
                    this.editViewTermReports(false);                                       
                    break;
                case 'edit-view-term-reports':
                    this.mainMenu(false);
                    this.enterMarks(false);
                    this.editViewTermReports(true);
                    break;    
            }
        },
        
       
    }
}
</script>