<template>
    <v-container fluid>
        <v-row
            justify="center"
        >            
            <v-col>
                <v-card
                    class="pa-0 mx-auto"
                    width="1105"                   
                >
                    <v-overlay
                        absolute
                        :value="overlay"                    
                        color="grey lighten-5"
                        opacity="0.9"                    
                    >                    
                        <v-expand-transition>
                            
                            <v-card
                                max-width="300"
                                class="mx-auto"
                                transition="scroll-y-transition"
                                v-show="expand"
                                light 
                            >
                                <v-toolbar
                                    color="primary"
                                    dark
                                >
                                    <v-toolbar-title>Select Class</v-toolbar-title>
                                </v-toolbar>
                                <div style="max-height: 400px; width: 250px; overflow-y: auto">    
                                <v-list light >
                                    <v-list-group
                                        v-for="item in formClasses"
                                        :key = "item.title"
                                        v-model="item.active"
                                        :prepend-icon="item.action"
                                        no-action
                                    >
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="item.title"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <v-list-item
                                            v-for="subItem in item.items"
                                            :key="subItem.title"
                                            @click="setSelectedClass(subItem.title)"                                       
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title v-text="subItem.title"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-group>
                                </v-list>
                                </div>                     
                            </v-card>
                            
                        </v-expand-transition>

                         <v-expand-transition>
                            <v-card
                                max-width="300"
                                class="mx-auto"
                                transition="scroll-y-transition"
                                v-show="expandTerms"
                                light 
                            >
                                <v-toolbar
                                    color="primary"
                                    dark
                                >
                                    <v-toolbar-title>{{ reportMenuTitle }}</v-toolbar-title>
                                </v-toolbar>
                                <v-list 
                                    light
                                    v-if="selectTerm" 
                                >
                                    <v-list-item-group>
                                        <v-list-item
                                            v-for="(item, i) in termsAvailable"
                                            :key="i"
                                            @click="setSelectedTerm(item)"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title> {{ item.year }} Term {{ item.term }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>    
                                </v-list>
                                <v-card-text
                                    v-else
                                >
                                    Select Format
                                    <v-radio-group
                                        v-model="radioGroup"
                                    >
                                        <v-radio
                                            label="PDF"
                                            value="pdf"
                                        ></v-radio>
                                        <v-radio
                                            label="Excel"
                                            value="excel"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-card-text>
                                <v-card-actions v-if="!selectTerm">
                                    <v-btn
                                        color="primary"
                                        outlined
                                        small
                                        @click="closeReportMenu"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        dark
                                        small
                                        @click="setFormat"
                                    >
                                        Generate Mark Sheet
                                    </v-btn>
                                </v-card-actions>    
                            </v-card>
                        </v-expand-transition>

                        <v-progress-circular 
                            transition="fade-transition" 
                            indeterminate 
                            size="64"
                            v-show="loading"
                            color="blue"
                        ></v-progress-circular>

                    </v-overlay>
                    
                    <v-card-title 
                        class="primary pa-0"
                    >                        
                        <v-row class="mx-0 white--text">
                            <v-col
                                class="pa-0 flex-grow-0 mr-4"
                                align-self="center"
                            >
                                <v-card 
                                    flat
                                    width="150"
                                    color="primary"
                                    class="white--text pl-4"
                                    style="font-size:0.7rem"
                                >
                                    {{ saveStatus }}

                                    <v-progress-circular
                                        indeterminate
                                        v-show="saving"
                                        size="16"
                                        width="3"
                                        class="ml-3"
                                        color="white"
                                    ></v-progress-circular>
                                
                                    
                                    <v-icon
                                        small
                                        color="white"
                                        v-show="saved"
                                        class="ml-3"
                                    >
                                        mdi-check-all
                                    </v-icon>
                                
                                    <v-icon
                                        small
                                        color="red"                   
                                        v-show="saveError"
                                        class="ml-3"
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-card> 
                                
                            </v-col>  

                            <v-col 
                                style="letter-spacing: 1px; text-transform: uppercase; font-weight: bold"
                                class="pa-0 text-subtitle-2  d-flex justify-center"
                                align-self="center"
                            >
                                Edit / View Term Reports
                            </v-col>
                            
                            <v-col 
                                class="pa-0 flex-grow-0"
                                align-self="center"
                            >
                                <v-btn
                                    text                
                                    x-small
                                    color="white"
                                    @click="closeEditViewTermReports"
                                >
                                    <v-icon>mdi-window-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>                
                    </v-card-title>

                    <v-container fluid class="pt-0 ml-0 mr-0">
                        <v-row>
                            <student-details></student-details>
                            <subject-records></subject-records>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col>
                
            </v-col>
        </v-row>

        <v-dialog
            v-model="dialogReport"
            max-width="90vw"
            persistent            
        >
            <v-card height="80vh">
                <iframe 
                    ref="pdf" 
                    style="width:100%; height:100%" 
                    frameBorder="0" 
                    :src="src"
                ></iframe>
                <v-overlay
                    absolute
                    :value="overlayReport"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>                
            </v-card>
            <v-container
                fluid
                class="pa-0"
            >
                <v-sheet>
                    <v-row
                        justify="end"
                        class="ma-0"
                    >
                        <v-btn
                            color="primary"
                            small
                            dark
                            @click="closeReport"
                            class="my-2 mr-4"
                        >
                            Close
                        </v-btn>
                    </v-row>
                </v-sheet>
            </v-container>
        </v-dialog>

        <v-bottom-sheet 
            v-model="sheet" 
            inset
            persistent
        >
            <v-row align="center" justify="center">
                <v-alert
                    :value="error"
                    prominent
                    type="error"
                    transition="scale-transition"
                    dense                        
                >
                    <v-row align="center">
                        <v-col>{{ errorMessage }}</v-col>
                    </v-row>
                </v-alert>
            </v-row>

            <v-sheet class="text-center px-10" height="200px">
                <v-row justify="end">
                    <div>
                        <v-btn
                            class="mt-1"
                            text
                            color="blue darken-3"
                            @click="closeBottomSheet"
                        >close</v-btn>
                    </div>
                </v-row>                
                <v-form ref="form">
                    <v-textarea                                       
                        :label="bottomSheetTitle"                        
                        hint="Max 215 Characters"
                        rows="3"                                           
                        counter                                             
                        no-resize
                        v-model="bottomSheetModel"
                        autofocus                        
                        persistent-hint
                        maxlength="215"                                                                                                                 
                    >                        
                    </v-textarea>
                </v-form>                    
            </v-sheet>
        </v-bottom-sheet>

        <v-dialog
            v-model="dialogDelete.display"
            max-width="550px"
        >
            <v-card>
                <v-card-title style="word-break:normal">{{ dialogDelete.text }}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="dialogDelete.cancel" 
                        color="primary"
                        outlined
                        @click="closeDelete"
                        small
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        v-if="dialogDelete.deleteSubject"
                        color="primary"
                        outlined
                        @click="deleteSubjectConfirm"
                        small
                    >
                        Delete
                    </v-btn>
                    <v-btn
                        v-if="dialogDelete.deleteStudent"
                        color="primary"
                        outlined
                        @click="deleteStudentConfirm"
                        small
                    >
                        Delete Student
                    </v-btn>
                    <v-btn
                        v-if="dialogDelete.ok"
                        color="primary"
                        outlined
                        @click="closeDelete"
                        small
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
            
        </v-dialog>
        
    </v-container>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import StudentDetails from './EditViewTermReportsStudentDetails';
import SubjectRecords from './EditViewTermReportsSubjectRecords';
export default {
    components: {
        StudentDetails,
        SubjectRecords,
    },
    
    created: function () {
        this.initialize();
    },

    data: () => ({       
       error: false,
       errorMessage: '',       
       rules: [
            value => {
                if( value.length <= 215){
                    
                    return true;
                }
                else{
                    return 'Max 215 characters';
                }
            }
        ],
        bottomSheetModel: '',
        overlayReport: false,
        src: '',
        selectTerm: false,
        selectedTerm: '',
        radioGroup: "pdf",          
    }),   

    computed: {
        ...mapGetters({
            overlay: 'termReports/getEditViewTermReportsOverlay',
            loading: 'termReports/getEditViewTermReportsLoading',
            expand: 'termReports/getEditViewTermReportsExpand',
            formClasses: 'termReports/getFormClasses',
            studentRecord: 'termReports/getSelectedStudentRecord',
            sheet: 'termReports/getCommentSheet',
            model: 'termReports/getBottomSheetModel',
            bottomSheetTitle: 'termReports/getBottomSheetTitle',
            dialogDelete: 'admin/getDialogDelete',
            selectedTable2Record: 'admin/getSelectedTable2Record',
            selectedTable1Record: 'admin/getSelectedTable1Record',
            saveStatus: 'termReports/getEditViewTermReportsSaveStatus',
            saving: 'termReports/getEditViewTermReportsSaving',
            saveError: 'termReports/getEditViewTermReportsSaveError',
            saved: 'termReports/getEditViewTermReportsSaved',
            expandTerms: 'termReports/getExpandTerms',
            termsAvailable: 'termReports/getTermsAvailable',
            dialogReport: 'termReports/getDialogReport',
            srcObject: 'termReports/getSrc',
            employeeID: 'auth/getEmployeeId',
        }),
        
        reportMenuTitle () {
            if(this.selectTerm) return "Select Report Term";
            else return "Select Format";
        },   
    },

    watch: {
        formClasses: {
            handler: function () {
                //console.log(val);                
                this.setLoading(false);
                this.setExpand(true);
                //console.log(`Loading: ${this.loading}`);
            }
        },

        model: {
            handler: function (val){
                console.log('model changed ..');
                console.log(val);
                console.log(this.studentRecord);                
                this.bottomSheetModel = this.studentRecord[val];
            }
        },

        studentRecord: {    
            handler: function (){                
                this.bottomSheetModel = this.studentRecord[this.model];
            }
        },

        expandTerms: {
            handler: function (val) {
                // console.log(`Expand Terms: ${val}`);
                console.log(this.termsAvailable);
                if(val) this.selectTerm = true;
            }
        },        
    },
    
    methods: {
        ...mapMutations({
            setOverlay: 'termReports/setEditViewTermReportsOverlay',
            setLoading: 'termReports/setEditViewTermReportsLoading',
            setExpand: 'termReports/setEditViewTermReportsExpand',
            setSelectedFormClass: 'termReports/setSelectedFormClass',
            setSelectedStudentRecord: 'termReports/setSelectedStudentRecord',
            setPagination: 'termReports/setPagination',
            setCommentSheet: 'termReports/setCommentSheet',
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',            
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
            setTable1Records: 'termReports/setTable1Records',
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects',
            setTermsAvailable: 'termReports/setTermsAvailable',
            setSubjects: 'admin/setSubjects',
            setCodedComments: 'termReports/setCodedComments',
            setDialogDelete: 'admin/setDialogDelete',
            setSnackbar: 'admin/setSnackbar',
            setExpandTerms: 'termReports/setExpandTerms',
            setDialogReport: 'termReports/setDialogReport',
            setMainMenuButton: 'termReports/setMainMenuButton',
            setFormClasses: 'termReports/setFormClasses',
            setFormClassesList: 'termReports/setFormClassesList',
            setFormTeacherClass: 'termReports/setFormTeacherClass',              
        }),

        ...mapActions({
            getTable1Record: 'termReports/getTable1Record',
            getTable2Records: 'termReports/getTable2Records',
            postTable1Record: 'termReports/postTable1Record',
            setErrorResponse: 'termReports/setErrorResponse',
            getCodedCommentsList: 'termReports/getCodedCommentsList',
            getSubjects: 'admin/getSubjects',
            deleteTable2Record: 'admin/deleteTable2Record',
            deleteTable1Record: 'admin/deleteTable1Record',
            downloadExcelMarksheet: 'termReports/dowloadExcelMarkSheet',
            getFormClasses: 'termReports/getFormClasses',
            getFormTeacherClass: 'termReports/getAssignedFormTeacherClass',
            getCodedComments: 'termReports/getCodedComments', 
        }),               
        
        initialize () {
            this.setMainMenuButton(true);
            this.setOverlay(true);
            this.setLoading(true);
            this.setFormClases();
        },

        async setFormClases () {
            let response = await this.getFormTeacherClass(this.employeeID);
            console.log(response);
            
            if(response.data.length > 0) this.setFormTeacherClass(response.data[0].class_id);
            else this.setFormTeacherClass('');
            response = await this.getFormClasses();
            
            this.setFormClassesList(response.data);
            let formClasses = [];
            let form1Classes = [];
            let form2Classes = [];
            let form3Classes = [];
            let form4Classes = [];
            let form5Classes = [];
            let form6Classes = [];            
            response.data.forEach(record => {
                switch(record.form_level){
                    case 1:
                    form1Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 2:
                    form2Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 3:
                    form3Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 4:
                    form4Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 5:
                    form5Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 6:
                    form6Classes.push({
                        title: record.class_id
                    });
                    break;
                }                
            });
            formClasses.push({
                title: 'Form 1',
                action: 'mdi-school',                
                items: form1Classes
            });
            formClasses.push({
                title: 'Form 2',
                action: 'mdi-school',                
                items: form2Classes
            });
            formClasses.push({
                title: 'Form 3',
                action: 'mdi-school',                
                items: form3Classes
            });
            formClasses.push({
                title: 'Form 4',
                action: 'mdi-school',                
                items: form4Classes
            });
            formClasses.push({
                title: 'Form 5',
                action: 'mdi-school',                
                items: form5Classes
            });
            formClasses.push({
                title: 'Form 6',
                action: 'mdi-school',                
                items: form6Classes
            });            
            this.setFormClasses(formClasses);
            
            await this.getCodedComments();
        },

        setSelectedClass (formClass) {            
            this.setSelectedFormClass(formClass);            
            this.setStudentRecords();
            this.setExpand(false);
            setTimeout(() => {
                this.setLoading(true);
            }, 600)           
            
        },

        async setStudentRecords () {
            try {
                const promiseCodedComments = this.getCodedCommentsList();
                const promiseTable1Records = this.getTable1Record();
                const promiseSubjects = this.getSubjects();

                const responses = await Promise.all([
                    promiseTable1Records,
                    promiseCodedComments,                    
                    promiseSubjects 
                ]);

                const [
                    { data: table1 }, 
                    , 
                    { data: subjects }
                ] = responses;

                this.makePagination(table1);
                this.setTable1Records(table1.data);
                if(table1.data.length > 0){
                    this.setSelectedStudentRecord(table1.data[0])
                }
                this.setSubjects(subjects);

                const { data } = await this.getTable2Records();
                this.setTable2Records(data.table2_records);
                this.setStudentSubjects(data.student_subjects);
                console.log(data.terms);
                let reportTerms = data.terms.map(value => {
                    let year = parseInt(value.year);
                    value.year = year + '-' + (year+1);
                    return value;
                })
                // console.log(reportTerms);
                this.setTermsAvailable(reportTerms);

                this.setLoading(false);
                this.$nextTick(() => {
                    this.setOverlay(false);
                })

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
                        
        }, 

        closeBottomSheet () {            
            this.setCommentSheet(false);
            this.updateRecord();
        },

        makePagination (data) {
            let pagination = {
                current_page: 1,
                last_page: data.data.length,
                next_page: 2,
                prev_page: null,
            }
            //console.log(pagination);
            this.setPagination(pagination);
        },

        closeEditViewTermReports () {
            console.log('closing...');
            this.setOverlay(true);
            this.setExpand(true);
        },

        async updateRecord(){
            console.log('updating...');
            this.setSaveStatus('Saving...');
            //console.log(this.bottomSheetModel);
            this.studentRecord[this.model] = this.bottomSheetModel;
            //this.bottomSheetModel = '';
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            //console.log(this.studentRecord);
            try{
                let response = await this.postTable1Record();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                console.log(response);
            } catch (error) {
                console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }
            
        },

        closeDelete () {
            let dialogDelete = {...this.getDialogDelete};
            dialogDelete.display = false;
            this.setDialogDelete(dialogDelete);
        },

        async deleteSubjectConfirm () {            
            this.closeDelete();

            this.$nextTick(() => {
                this.setOverlay(true);
                this.setLoading(true);
            })
            
            try {
                await this.deleteTable2Record();

                const { data } = await this.getTable2Records();
                this.setTable2Records(data.table2_records);
                this.setStudentSubjects(data.student_subjects);
                
                this.setSnackbar({
                    text: `${this.selectedTable2Record.title} deleted.`,
                    display: true,
                    color: 'primary',
                });
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSnackbar({
                    text: `Error occured`,
                    display: true,
                    color: 'red',
                });
            }

            this.setOverlay(false);
            this.setLoading(false);
        },

        async deleteStudentConfirm () {            
            this.closeDelete();

            this.$nextTick(() => {
                this.setOverlay(true);
                this.setLoading(true);
            })

            try {
                await this.deleteTable1Record();               

                await this.setStudentRecords();

                this.setSnackbar({
                    text: `${this.selectedTable1Record.first_name} ${this.selectedTable1Record.last_name} deleted.`,
                    display: true,
                    color: 'primary',
                });
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSnackbar({
                    text: `Error occured`,
                    display: true,
                    color: 'red',
                });
            }

            this.setOverlay(false);
            this.setLoading(false);
        },

        setSelectedTerm (item) {
            this.srcObject.term = item.term;
            this.selectedTerm = item;
            
            if(this.srcObject.report == "mark-sheet"){
                this.selectTerm = false;
            }
            else if (this.srcObject.report == "rank-sheet") {
                let srcArray = [];
                Object.keys(this.srcObject).forEach(key => {
                    if(key != "report"){
                        srcArray.push(this.srcObject[key]);
                    }
                })
                this.src = srcArray.join("/");
                console.log(this.src);
                this.setExpandTerms(false);
                setTimeout(() => {
                    this.setOverlay(false);
                    this.openReport();
                }, 700);
            }
           
        },

        setFormat () {           
            let srcArray = [];
            
            if(this.radioGroup == "excel"){
                this.setExpandTerms(false);
                this.download();
            }
            else{
                Object.keys(this.srcObject).forEach(key => {
                    if(key != "report" ){
                        srcArray.push(this.srcObject[key]);
                    }
                })
                this.src = srcArray.join("/");
                this.openReport();
            }            

        },

        openReport () {
            this.setDialogReport(true);
            this.overlayReport = true;
            this.$nextTick( function () {
                this.$refs.pdf.onload = () => {
                    console.log('report loaded');
                    this.overlayReport = false
                }
            })
        },

        download () {
            this.setLoading(true);
            console.log(this.selectedTerm);
            let fileName = this.srcObject.classId + ' Marksheet '
            + this.selectedTerm.title + ' '
            + this.srcObject.year + '-' + (this.srcObject.year + 1) + '.xlsx';
            this.downloadExcelMarksheet()
            .then(response => {
                // console.log(response);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                link.setAttribute('download', fileName);
               
                document.body.appendChild(link);
                link.click();
                // this.overlayDownload = false;
                this.setOverlay(false);
                this.setLoading(false);       
            })
            .catch( error => {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.overlayDownload = false;
                this.setOverlay(false);
                this.setLoading(false); 
            })
        },
        
        closeReport () {
            this.setDialogReport(false);
            this.src = null;
        },

        closeReportMenu () {            
            this.setExpandTerms(false);
            setTimeout(() => {
                this.setOverlay(false);
            }, 600)                       
        }
    },

    

}
</script>

<style scoped>
    ::v-deep .v-text-field__slot textarea{
        font-size: 12px;
    }
</style>